// Not an enum since it will have more props
import {t} from '@lingui/macro'
import {MessageDescriptor} from '@lingui/core'

export interface CookingMethod {
  readonly id: string,
  readonly name: MessageDescriptor,
}

// TODO: Get strings elsewhere
const CookingMethods: CookingMethod[] = [
  {
    id: 'sear',
    name: t`sear`,
  },
  {
    id: 'sautéed',
    name: t`sautéed`,
  },
  {
    id: 'fry',
    name: t`fry`,
  },
  {
    id: 'stir-fry',
    name: t`stir-fry`,
  },
  {
    id: 'braise',
    name: t`braise`,
  },
  {
    id: 'pressure-cook',
    name: t`pressure-cook`,
  },
  {
    id: 'barbecue',
    name: t`barbecue`,
  },
]

export default CookingMethods
