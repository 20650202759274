import React from "react";
import {RecipeStep} from "./RecipeStepBuilder";
import {getRandomItemFromList} from './Util'
import {Trans} from '@lingui/macro'
import {CutNames, FormsNames} from './CutFormService'
import {i18n} from './App'

function sentenceCase(str: string): string {
  return str[0].toUpperCase() + str.substr(1, str.length)
}

// Probably can move each RecipeStep into it's own component
// Renders a list of recipe steps generated by the recipe step builder
function RecipeSteps(props: {recipeSteps: RecipeStep[]}) {
  const recipeStepItems = props.recipeSteps.map(({ingredient, form, seasoning, cookingMethod}: RecipeStep) => {
    const cutWord = sentenceCase(CutNames[form.cut])
    const formName = i18n._(FormsNames[form.form])
    const cookingMethodTrans = i18n._(cookingMethod.name)
    const cookingMethodName = sentenceCase(cookingMethodTrans)
    const ingredientName = i18n._(ingredient.name)
    const ingredientNameTitle = sentenceCase(ingredientName)
    return (
      <div key={ingredient.id}>
        <h4>{ingredientNameTitle}</h4>
        <ol>
          <li><span className='cut'>{cutWord}</span> {ingredientName} <Trans>into</Trans> <span className='form'>{formName}</span><Trans>.</Trans></li>
          <li><Trans>Season it with</Trans> <span className='seasoning'><Trans id={seasoning.name.id}/></span><Trans>.</Trans></li>
          <li><span className='cooking-method'>{cookingMethodName}</span> <Trans>until satisfied.</Trans></li>
        </ol>
      </div>
    )
  })
  const adjective = getRandomItemFromList(adjectives)
  return <div className='recipe-steps'>
    {props.recipeSteps.length > 0 &&
    <div>
      <h2><Trans>Steps</Trans></h2>
      <div>
        {recipeStepItems}
      </div>
        <div><Trans>Combine to make it look</Trans> <span className='adjective'>{adjective}</span><Trans>.</Trans> <Trans>Bon appétit!</Trans></div>
    </div>
    }
  </div>
}

const adjectives = [
  'cute',
  'adorable',
  'beautiful',
  'charming',
  'delightful',
  'pleasant',
  'pretty',
  'dainty',
  'ambrosial',
  'appealing',
  'attractive',
  'captivating',
  'charming',
  'cute',
  'darling',
  'dear',
  'delectable',
  'delicious',
  'delightful',
  'dishy',
  'dreamy',
  'fetching',
  'heavenly',
  'hot',
  'luscious',
  'pleasing',
  'precious',
  'sexy',
  'suave',
]

export default RecipeSteps
