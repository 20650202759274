declare const gtag: GTag
type GTag = (command: string, param?: string, params?: object) => void
export interface GTagEventParams {
  event_category: string,
  event_label: string,
  value?: number,
  transport_type?: string,
  event_callback?: Function,
}
class AnalyticsService {
  event(action: string, params: GTagEventParams): void {
    try {
      if (typeof gtag !== 'undefined') {
        if (typeof params.value === 'undefined') {
          params.value = 1
        }
        gtag('event', action, params)
      } else {
        console.error('Google Analytics blocked', {action, params})
      }
    } catch (error) {
      console.error(error)
    }
  }
  createOutboundTracker(url: string): () => boolean  {
    return () => {
      this.event('click', {
        event_category: 'outbound',
        event_label: url,
        transport_type: 'beacon',
      })
      return false
    }
  }
}

export default AnalyticsService