import React, {
  useEffect,
  useRef,
  useState
} from 'react'
import './App.css'
import RecipeForm from "./RecipeForm";
import RecipeSteps from "./RecipeSteps";
import RecipeIngredients from "./RecipeIngredients";
import IngredientService from "./IngredientService";
import RecipeStepBuilder, {RecipeStep} from "./RecipeStepBuilder";
import Ingredient from "./Ingredient";
import CutFormService from './CutFormService'
import {I18nProvider} from '@lingui/react'
import {Trans} from '@lingui/macro'
import catalogsZh from './locales/zh/messages'
import catalogsEn from './locales/en/messages'
import {setupI18n} from '@lingui/core'
import {CssBaseline} from '@material-ui/core'
import AnalyticsService from './AnalyticsService'
import {createMuiTheme, ThemeProvider} from '@material-ui/core/styles'
import customTheme from './CustomTheme'
import Nature from './Nature'
import animateScrollTo from 'animated-scroll-to'
import {classNames as cn} from './Util'

const catalogs = {
  en: catalogsEn,
  zh: catalogsZh
}
let language = window.navigator.language.slice(0, 2) === 'zh' ? 'zh' : 'en'
export const i18n = setupI18n({catalogs, language})

function App() {
  const ingredientService = new IngredientService()
  const cutFormService = new CutFormService()
  const analyticsService = new AnalyticsService()
  const recipeStepBuilder = new RecipeStepBuilder(ingredientService, cutFormService)
  const initialRecipeSteps: RecipeStep[] = []
  const initialIngredients: Ingredient[] = []
  const [recipeSteps, setRecipeSteps] = useState(initialRecipeSteps)
  const [ingredients, setIngredients] = useState(initialIngredients)
  const [isDancing, setIsDancing] = useState(false)

  const [mainIngredient, setMainIngredient] = useState<Ingredient | null>(null)
  const recipeStepsEl = useRef<HTMLDivElement>(null)
  const theme = createMuiTheme(customTheme)
  function renderRecipe(onComplete?: Function) {
    setIsDancing(true)
    setIngredients([])
    setRecipeSteps([])
    // TODO: Pot becomes becomes zoomed out while scrolling down cause dancing class removed
    setTimeout(() => {
      const recipeSteps: RecipeStep[] = recipeStepBuilder.build(mainIngredient);
      const ingredients = recipeSteps.map(({ingredient}: {ingredient: Ingredient}) => ingredient)
      setRecipeSteps(recipeSteps)
      setIngredients(ingredients)
      setIsDancing(false)
      if (typeof onComplete === 'function') {
        onComplete()
      }
    }, 2000)
  }
  useEffect(() => {
    if (recipeSteps.length > 0 && !isDancing) {
      animateScrollTo(recipeStepsEl.current as HTMLDivElement, {
        speed: 800,
      })
    }
  }, [recipeSteps.length, isDancing])
  return (
    <ThemeProvider theme={theme}>
      <I18nProvider i18n={i18n} language={language}>
        <CssBaseline/>
        <div className={`App ${language}`}>
          <header className="App-header">
            <div className={cn({'center-padding': true, hidden: isDancing})}>
              <h1 className='title'><Trans>Recipe Generator</Trans></h1>
              <RecipeForm buildRecipe={renderRecipe}
                          mainIngredient={mainIngredient}
                          setMainIngredient={setMainIngredient}/>
            </div>
            <Nature isDancing={isDancing} buildRecipe={renderRecipe}/>
            <div className="recipe-content" ref={recipeStepsEl}>
              <RecipeIngredients ingredients={ingredients}/>
              <RecipeSteps recipeSteps={recipeSteps}/>
              {recipeSteps.length > 0 &&
              <p><Trans>We would love to know your <a href='https://forms.gle/Bg7HXdZSHLdKmK7Q9' target='_blank' onClick={analyticsService.createOutboundTracker('https://forms.gle/Bg7HXdZSHLdKmK7Q9')} rel='noopener noreferrer'>feedback!</a></Trans></p>
              }
            </div>
          </header>
          <p><Trans>Made with <span role="img" aria-label="red heart">❤️</span> in San Francisco</Trans></p>
          <p><Trans>Written by <a className='author' href='https://tlee.io' target='_blank' onClick={analyticsService.createOutboundTracker('https://tlee.io')} rel='noopener noreferrer'>Thomas Lee</a></Trans></p>
        </div>
      </I18nProvider>
    </ThemeProvider>
  )
}

export default App
