import React, {useState} from 'react'
import IngredientService from './IngredientService'
import Ingredient from './Ingredient'
import FoodType from './FoodType'
import AnalyticsService from './AnalyticsService'
import {t} from '@lingui/macro'
import {I18n} from '@lingui/react'
import {i18n} from './App'
import {Autocomplete} from '@material-ui/lab'
import {TextField} from '@material-ui/core'

interface IngredientAutocompleteProps {
  onChange:(ingredient: Ingredient | null) => void,
}
interface IngredientOption {
  id: string,
  title: string,
}
function getIngredientOption(ingredient: Ingredient): IngredientOption {
  return {
    id: ingredient.id,
    title: i18n._(ingredient.name.id)
  }
}

function IngredientAutocomplete(props: IngredientAutocompleteProps) {
  const analyticsService = new AnalyticsService()
  const ingredientService = new IngredientService()
  const ingredients = ingredientService.getByFoodTypes([FoodType.Meat, FoodType.Veg])
  const options: IngredientOption[] = ingredients.map(getIngredientOption)
  const [hasEngaged, setHasEngaged] = useState(false)
  const [mainIngredientOption, setMainIngredientOption] = useState<IngredientOption | null>(null)
  function onChange(event: React.ChangeEvent<{}>, value: IngredientOption | null, reason: string) {
    const selectedIngredient = value ? findIngredientById(value.id) : null
    if (!hasEngaged) {
      setHasEngaged(true)
      analyticsService.event('engage', {
        event_category: 'main-ingredient',
        event_label: 'input'
      })
    }
    if (selectedIngredient) {
      analyticsService.event('select', {
        event_category: 'main-ingredient',
        event_label: selectedIngredient.id,
      })
      setMainIngredientOption(getIngredientOption(selectedIngredient))
      props.onChange(selectedIngredient)
    } else {
      setMainIngredientOption(null)
      props.onChange(null)
    }
  }
  function trackFailedSearch(event: React.FocusEvent<HTMLInputElement>) {
    const value = event.target.value
    const selectedIngredient = findIngredientByName(value)
    if (!selectedIngredient) {
      if (value.trim().length) {
        analyticsService.event('failed-search', {
          event_category: 'main-ingredient',
          event_label: value.trim(),
        })
      }
    } else {
      setMainIngredientOption(getIngredientOption(selectedIngredient))
      props.onChange(selectedIngredient)
    }
  }
  function findIngredientById(ingredientId: string): Ingredient {
    const selectedIngredients = ingredients.filter(ingredient => ingredient.id === ingredientId)
    return selectedIngredients[0]
  }
  function findIngredientByName(ingredientName: string): Ingredient {
    const selectedIngredients = ingredients.filter(ingredient => i18n._(ingredient.name) === ingredientName)
    return selectedIngredients[0]
  }
  return <div>
    <I18n>
      {({i18n}) => (
        <Autocomplete
          id='main-ingredient'
          blurOnSelect='touch'
          options={options}
          value={mainIngredientOption}
          getOptionLabel={(option) => option.title}
          getOptionSelected={(option, value) => option.id === value.id}
          placeholder={i18n._(t`Type an ingredient e.g. beef or bok-choy`)}
          openText={i18n._(t`Open`)}
          clearText={i18n._(t`Clear`)}
          noOptionsText={i18n._(t`No options`)}
          onChange={onChange}
          renderInput={(params) =>
            <TextField
              {...params}
              onBlur={trackFailedSearch}
              label={i18n._(t`Optional main ingredient`)}
              variant="outlined" />}
        />
      )}
    </I18n>
  </div>
}

export default IngredientAutocomplete
