import CutForm, {Cut, Form} from './CutForm'
import {getRandomItemFromList} from './Util'
import {i18n} from './App'
import {t} from '@lingui/macro'

class CutFormService {
  constructor() {
    Object.assign(CutNames, {
      [Cut.Chop]: i18n._(t`Chop`),
      [Cut.Slice]: i18n._(t`Slice`),
      [Cut.Dice]: i18n._(t`Dice`),
      [Cut.Mince]: i18n._(t`Mince`),
    })
    Object.assign(FormsNames, {
      [Form.Chunks]: i18n._(t`chunks`),
      [Form.Slices]: i18n._(t`slices`),
      [Form.Cubes]: i18n._(t`cubes`),
      [Form.SmallPieces]: i18n._(t`small pieces`),
    })
  }

  // Get random Forms
  getRandomList(countCutForms: number): CutForm[] {
    const results = []
    for (let i=0; i < countCutForms; i++) {
      results.push(getRandomItemFromList(cutForms))
    }
    return results
  }
}

export const CutNames : {[index: string]: string} = {}
export const FormsNames : {[index: string]: string} = {}

const cutForms: CutForm[] = [
  {
    id: Cut.Slice,
    cut: Cut.Slice,
    form: Form.Slices,
  },
  {
    id: Cut.Dice,
    cut: Cut.Dice,
    form: Form.Cubes,
  },
  {
    id: Cut.Mince,
    cut: Cut.Mince,
    form: Form.SmallPieces,
  },
  {
    id: Cut.Slice,
    cut: Cut.Slice,
    form: Form.SmallPieces,
  },
  {
    id: Cut.Chop,
    cut: Cut.Chop,
    form: Form.Chunks,
  },
]

export default CutFormService
