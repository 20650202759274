import React from "react";
import Ingredient from "./Ingredient";
import {Trans} from '@lingui/macro'

// Renders a list of ingredients used by the recipe
function RecipeIngredients(props: {ingredients: Ingredient[]}) {
  const ingredientList = props.ingredients.map(ingredient =>
    <li key={ingredient.id}><Trans id={ingredient.name.id}/></li>
  )
  return <div>
    {props.ingredients.length > 0 &&
    <h2><Trans>Ingredients</Trans></h2>
    }
    <ol>{ingredientList}</ol>
  </div>
}

export default RecipeIngredients
