import React, {useEffect, useRef, useState} from 'react'
import {ReactComponent as Pot} from './pot.svg'
import {ReactComponent as HillsClouds} from './hills-clouds.svg'
import {classNames as cn} from './Util'

interface NatureProps {
  isDancing: boolean,
  buildRecipe: (onComplete?: Function) => void,
}
export default function Nature(props: NatureProps): JSX.Element {
  const [isLidShaking, setIsLidShaking] = useState<boolean>(false)
  // TODO: Lid doesn't shake always on dance. Gave up, random bonus feature :P
  // TODO: Make it less complex. Want to clear interval onPotClick
  const refContainer = useRef({lidShakingIntervalId: 0})
  function startLidShakingInterval() {
    refContainer.current.lidShakingIntervalId = window.setInterval(() => {
      setIsLidShaking(isLidShaking => !isLidShaking)
    }, 3000)
    return refContainer.current.lidShakingIntervalId
  }
  // Lid shake every 8s. Run effect once only.
  useEffect(() => {
    const lidShakingIntervalId = startLidShakingInterval()
    return () => clearInterval(lidShakingIntervalId)
  }, [])
  function onPotClick() {
    setIsLidShaking(false)
    clearInterval(refContainer.current.lidShakingIntervalId)
    props.buildRecipe(() => {
      startLidShakingInterval()
    })
  }

  const potClassNames = cn({
    pot: true,
    dancing: props.isDancing,
    lidShaking: isLidShaking,
  })
  return <div className='nature'>
    <HillsClouds/>
    <Pot className={potClassNames} onClick={onPotClick}/>
  </div>
}