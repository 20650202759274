import Ingredient from "./Ingredient";
import FoodType from "./FoodType";
import {getRandomItemFromList} from './Util'
import {t} from '@lingui/macro'

function reduceIngredientsByType(finalList: Ingredient[], ingredients: Ingredient[]): Ingredient[] {
  finalList.push(getRandomItemFromList(ingredients))
  return finalList
}

/**
 * Queries ingredients.
 */
class IngredientService {
  // Returns two random Ingredients of Meat and Veg FoodType
  getRandomList(): Ingredient[] {
    const ingredientsByType: Ingredient[][] = []
    ingredientsByType.push(ingredients.filter(ingredient => ingredient.foodType === FoodType.Veg))
    ingredientsByType.push(ingredients.filter(ingredient => ingredient.foodType === FoodType.Meat))
    const randomIngredients = ingredientsByType.reduce(reduceIngredientsByType, [])
    return randomIngredients
  }
  getRandomListByFoodType(targetFoodType: FoodType, countOfFoods: number): Ingredient[] {
    const ingredientsOfFoodType = ingredients.filter(ingredient => ingredient.foodType === targetFoodType)
    const results = []
    for (let i=0; i < countOfFoods; i++) {
      results.push(getRandomItemFromList(ingredientsOfFoodType))
    }
    return results
  }
  getByFoodTypes(foodTypes: FoodType[]): Ingredient[] {
    const matchingIngredients = ingredients.filter(ingredient => foodTypes.includes(ingredient.foodType))
    return matchingIngredients
  }
}

export default IngredientService

// TODO: Move names to JSON translations content file
// Private list of ingredients. Treat as a data store
const ingredients: Ingredient[] = [
  {
    id: 'egg',
    foodType: FoodType.Meat,
    name: t`egg`,
  },
  {
    id: 'clam',
    foodType: FoodType.Meat,
    name: t`clam`,
  },
  {
    id: 'chicken',
    foodType: FoodType.Meat,
    name: t`chicken`,
  },
  {
    id: 'lobster',
    foodType: FoodType.Meat,
    name: t`lobster`,
  },
  {
    id: 'crab',
    foodType: FoodType.Meat,
    name: t`crab`,
  },
  {
    id: 'shrimp',
    foodType: FoodType.Meat,
    name: t`shrimp`,
  },
  {
    id: 'pork',
    foodType: FoodType.Meat,
    name: t`pork`,
  },
  {
    id: 'beef',
    foodType: FoodType.Meat,
    name: t`beef`,
  },
  {
    id: 'salmon',
    foodType: FoodType.Meat,
    name: t`salmon`,
  },
  {
    id: 'black cod',
    foodType: FoodType.Meat,
    name: t`black cod`,
  },
  {
    id: 'salt',
    foodType: FoodType.Spice,
    name: t`salt`,
  },
  {
    id: 'white pepper',
    foodType: FoodType.Spice,
    name: t`white pepper`,
  },
  {
    id: 'black pepper',
    foodType: FoodType.Spice,
    name: t`black pepper`,
  },
  {
    id: 'chili oil',
    foodType: FoodType.Spice,
    name: t`chili oil`,
  },
  {
    id: 'sugar',
    foodType: FoodType.Spice,
    name: t`sugar`,
  },
  {
    id: 'brown sugar',
    foodType: FoodType.Spice,
    name: t`brown sugar`,
  },
  {
    id: 'curry',
    foodType: FoodType.Spice,
    name: t`curry`,
  },
  {
    id: 'sesame oil',
    foodType: FoodType.Spice,
    name: t`sesame oil`,
  },
  {
    id: 'soy sauce',
    foodType: FoodType.Spice,
    name: t`soy sauce`,
  },
  {
    id: 'dark soy sauce',
    foodType: FoodType.Spice,
    name: t`dark soy sauce`,
  },
  {
    id: 'summer squash',
    foodType: FoodType.Veg,
    name: t`summer squash`,
  },
  {
    id: 'peanut',
    foodType: FoodType.Veg,
    name: t`peanut`,
  },
  {
    id: 'spinach',
    foodType: FoodType.Veg,
    name: t`spinach`,
  },
  {
    id: 'garlic',
    foodType: FoodType.Veg,
    name: t`garlic`,
  },
  {
    id: 'Napa cabbage',
    foodType: FoodType.Veg,
    name: t`Napa cabbage`,
  },
  {
    id: 'baby bok-choy',
    foodType: FoodType.Veg,
    name: t`baby bok-choy`,
  },
  {
    id: 'eggplant',
    foodType: FoodType.Veg,
    name: t`eggplant`,
  },
  {
    id: 'potato',
    foodType: FoodType.Veg,
    name: t`potato`,
  },
  {
    id: 'zucchini',
    foodType: FoodType.Veg,
    name: t`zucchini`,
  },
  {
    id: 'bell pepper',
    foodType: FoodType.Veg,
    name: t`bell pepper`,
  },
  {
    id: 'mushroom',
    foodType: FoodType.Veg,
    name: t`mushroom`,
  },
  {
    id: 'oyster mushroom',
    foodType: FoodType.Veg,
    name: t`oyster mushroom`,
  },
  {
    id: 'onion',
    foodType: FoodType.Veg,
    name: t`onion`,
  },
  {
    id: 'carrot',
    foodType: FoodType.Veg,
    name: t`carrot`,
  },
  {
    id: 'tomato',
    foodType: FoodType.Veg,
    name: t`tomato`,
  },
  {
    id: 'rice',
    foodType: FoodType.Carb,
    name: t`rice`,
  },
  {
    id: 'noodles',
    foodType: FoodType.Carb,
    name: t`noodles`,
  },
  {
    id: 'sticky rice',
    foodType: FoodType.Carb,
    name: t`sticky rice`,
  },
]
