import React from "react";
import IngredientAutocomplete from './IngredientAutocomplete'
import Ingredient from './Ingredient'
import AnalyticsService, {GTagEventParams} from './AnalyticsService'
import { Trans } from "@lingui/macro";
import {Button} from '@material-ui/core'

interface RecipeFormProps {
  buildRecipe: () => void,
  mainIngredient: Ingredient | null,
  setMainIngredient: React.Dispatch<React.SetStateAction<Ingredient | null>>,
}

// Renders a generate recipe button and an input to choose the main ingredient
function RecipeForm(props: RecipeFormProps): JSX.Element {
  const analyticsService = new AnalyticsService()
  function buildRecipeWithIngredient(event: React.FormEvent) {
    event.preventDefault()
    const params: GTagEventParams = {
      event_category: 'generate-recipe',
      event_label: '',
    }
    if (props.mainIngredient) {
      params.event_label = props.mainIngredient.id
    }
    analyticsService.event('generate', params)
    props.buildRecipe()
  }
  function onSelect(mainIngredient: Ingredient | null) {
    // TODO: Set doesn't save in state. Next build main is null
    // Removal of blur works, but state doesn't clear
    props.setMainIngredient(mainIngredient)
  }
  return <form onSubmit={buildRecipeWithIngredient}>
    <Button
      variant='contained'
      color='primary'
      className='generate-recipe'
      onClick={buildRecipeWithIngredient}>
      <Trans>Generate recipe</Trans>
    </Button>
    <IngredientAutocomplete onChange={onSelect} />
  </form>
}

export default RecipeForm