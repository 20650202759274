import Ingredient from './Ingredient'
import CutForm from './CutForm'
import CookingMethods, {CookingMethod} from './CookingMethods'
import IngredientService from './IngredientService'
import {getRandomItemFromList} from './Util'
import CutFormService from './CutFormService'
import FoodType from './FoodType'

// Components and actions needed to prepare a single ingredient
export interface RecipeStep {
  readonly ingredient: Ingredient,
  readonly seasoning: Ingredient,
  readonly form: CutForm,
  readonly cookingMethod: CookingMethod,
}

export interface BuildRecipe {
  (mainIngredient: Ingredient | null): RecipeStep[]
}

// Orchestrates building recipes from a list of ingredients
class RecipeStepBuilder {
  private readonly ingredientsService: IngredientService
  private readonly cutFormService: CutFormService
  constructor(ingredientService: IngredientService, cutFormService: CutFormService) {
    this.ingredientsService = ingredientService
    this.cutFormService = cutFormService
  }
  // Generates a RecipeStep for each given ingredient.
  build: BuildRecipe = (mainIngredient: Ingredient | null): RecipeStep[] => {
    const cutForms = this.cutFormService.getRandomList(2)
    const seasonings = this.ingredientsService.getRandomListByFoodType(FoodType.Spice, 2)
    let ingredients: Ingredient[] = []
    if (!mainIngredient) {
      ingredients = this.ingredientsService.getRandomList()
    } else if (mainIngredient.foodType === FoodType.Meat) {
      ingredients.push(mainIngredient)
      ingredients = ingredients.concat(this.ingredientsService.getRandomListByFoodType(FoodType.Veg, 1))
    } else if (mainIngredient.foodType === FoodType.Veg) {
      ingredients = ingredients.concat(this.ingredientsService.getRandomListByFoodType(FoodType.Meat, 1))
      ingredients.push(mainIngredient)
    }
    const recipeSteps = ingredients.map((ingredient, i) => {
      const cookingMethod = getRandomItemFromList(CookingMethods)
      const form = cutForms[i]
      const seasoning = seasonings[i]
      return {
        ingredient,
        form,
        seasoning,
        cookingMethod,
      }
    })
    return recipeSteps
  }
}

export default RecipeStepBuilder