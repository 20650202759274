// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/random
// The maximum is inclusive and the minimum is inclusive

function getRandomIntInclusive(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function getRandomItemFromList(list: any[]) {
  return list[getRandomIntInclusive(0, list.length - 1)]
}

/**
 * Class names as keys; whether they should be used are booleans as values.
 */
interface ClassNameSet {
  [index: string]: boolean
}

/**
 * Join keys as a space-delimited string.
 * @param classNameSet e.g. {hidden: false, red: true} outputs 'red'
 */
export function classNames(classNameSet: ClassNameSet): string {
  return Object.entries(classNameSet)
    .reduce((classNames, [className, isSet]) => {
      return isSet ? `${classNames} ${className}` : classNames;
    }, '')
}