
// https://en.wikipedia.org/wiki/Outline_of_food_preparation
// May expand into food prep of other forms than cutting e.g. soaking
export enum Cut {
  Chop = 'chop',
  Slice = 'slice',
  Dice = 'dice',
  Mince = 'mince',
}

// TODO: Get strings elsewhere
export enum Form {
  Chunks = 'chunks',
  Slices = 'slices',
  Cubes = 'cubes',
  SmallPieces = 'small pieces',
}

interface CutForm {
  readonly id: Cut,
  readonly cut: Cut,
  readonly form: Form,
}

export default CutForm